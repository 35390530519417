import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="auto-decline-radio-disable"
export default class extends Controller {
  static targets = ['select', 'radios'];
  connect() {
    this.radioButtons = this.radiosTarget.querySelectorAll('input');
    this.lengthSelected();
  }

  lengthSelected() {
    this.radiosDisabled = this.selectTarget.value === '0';
    this.updateRadios();
  }

  updateRadios() {
    this.radioButtons.forEach((radio) => {
      radio.disabled = this.radiosDisabled;
    });
  }
}
