import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';
import { Offcanvas } from 'bootstrap';

export default class extends Controller {
  static targets = ['checkbox', 'selectAllButton', 'bulkActionsUI', 'searchFilterUI', 'dropdown'];
  static values = {
    noMultiplePitchesUrl: String,
    id: String,
    castingCallId: String,
    detailsUrl: String
  };

  connect() {
    this.selectedCheckboxes = new Set();

    // Initialize pre-checked checkboxes
    this.getCheckedCheckboxes().forEach(checkbox => {
      this.selectedCheckboxes.add(checkbox);
    });

    // Initialize offcanvas
    const offcanvasElement = document.querySelector(this.element.getAttribute('data-bs-target'));
    if (offcanvasElement) {
      this.offcanvasInstance = new Offcanvas(offcanvasElement, {
        backdrop: true,
        keyboard: true,
        scroll: false
      });
    }
        
    if (this.hasRequiredTargets()) {
      this.updateBulkActionsUI();
    }
  }

  hasRequiredTargets() {
    return (
      this.hasSelectAllButtonTarget &&
            this.hasBulkActionsUITarget &&
            this.hasSearchFilterUITarget &&
            this.hasDropdownTarget
    );
  }

  getCheckedCheckboxes() {
    return document.querySelectorAll('input[data-pitch-review-target="checkbox"]:checked');
  }

  getAllCheckboxes() {
    return Array.from(this.element.querySelectorAll('input[data-pitch-review-target="checkbox"]'));
  }

  get checkedCheckboxTargets() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked);
  }

  get selectedCount() {
    return this.selectedCheckboxes.size;
  }

  shouldShowOffcanvas() {
    return window.innerWidth < 1200;
  }

  showOffcanvas() {
    const offcanvasElement = document.querySelector('#pitchDetailPanel');
    if (!offcanvasElement) {
      return;
    }

    this.offcanvasInstance = Offcanvas.getOrCreateInstance(offcanvasElement);
    this.offcanvasInstance.show();
  }

  getSelectedPitchIds() {
    return Array.from(this.selectedCheckboxes).map((checkbox) => checkbox.value);
  }

  toggleSelectAll() {
    const selectedCount = this.selectedCheckboxes.size;
    const shouldSelectAll = selectedCount === 0;
    this.isBulkUpdating = true;

    this.getAllCheckboxes().forEach(checkbox => {
      checkbox.checked = shouldSelectAll;
      if (shouldSelectAll) {
        this.selectedCheckboxes.add(checkbox);
      } else {
        this.selectedCheckboxes.delete(checkbox);
      }
    });

    this.isBulkUpdating = false;
    this.updateBulkActionsUI();

    const event = new CustomEvent('pitchSelectionChanged', {
      detail: { 
        count: this.selectedCount,
        isBulkAction: true
      },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
    this.checkAndDisplayNoOrMultipleSelected();
  }

  handleCheckboxChange(event) {
    if (this.isBulkUpdating) return;

    const checkbox = event.target;
        
    if (checkbox.checked) {
      this.selectedCheckboxes.add(checkbox);
    } else {
      this.selectedCheckboxes.delete(checkbox);
    }
        
    this.updateBulkActionsUI();
    this.checkAndDisplayNoOrMultipleSelected();
  }

  async checkAndDisplayNoOrMultipleSelected() {
    const selectedCheckboxes = this.getCheckedCheckboxes();
    const selectedCount = selectedCheckboxes.length;

    if (selectedCount >= 0) {
      try {
        const noOrMultipleHtml = await this.fetchNoOrMultipleSelected();
        this.displayContentInRightPanel(noOrMultipleHtml);
      } catch (error) {
        console.error('Error fetching no or multiple selected template:', error);
      }
    }
  }

  updateBulkActionsUI() {
    if (!this.hasRequiredTargets()) return;
    const selectedCount = this.selectedCheckboxes.size;
    if (selectedCount > 0) {
      this.selectAllButtonTarget.innerText = 'Deselect All';
      this.bulkActionsUITarget.classList.remove('d-none');
      this.searchFilterUITarget.classList.add('d-none');
      this.dropdownTarget.querySelector('button').querySelector('span').querySelector('span').innerText = `Bulk Actions (${selectedCount})`;

      const event = new CustomEvent('pitchSelectionChanged', {
        detail: { count: selectedCount },
        bubbles: true
      });
      this.element.dispatchEvent(event);
    } else {
      this.selectAllButtonTarget.innerText = 'Select All';
      this.bulkActionsUITarget.classList.add('d-none');
      this.searchFilterUITarget.classList.remove('d-none');

      const event = new CustomEvent('pitchSelectionChanged', {
        detail: { count: 0 },
        bubbles: true
      });
      this.element.dispatchEvent(event);
    }
  }

  handleClick(event) {
    event.stopPropagation();
    event.preventDefault();

    const selectedCount = this.getCheckedCheckboxes().length;

    if (selectedCount > 0) {
      // If checkboxes are checked, fetch and show the no/multiple selected content
      this.fetchNoOrMultipleSelected()
        .then(html => {
          this.displayContentInRightPanel(html);
          if (this.shouldShowOffcanvas()) {
            this.showOffcanvas();
          }
        })
        .catch(error => console.error('Error fetching no or multiple selected template:', error));
      return;
    }

    // If no checkboxes are checked, show pitch details
    this.fetchPitchDetails()
      .then(html => {
        this.displayContentInRightPanel(html);
        if (this.shouldShowOffcanvas()) {
          this.showOffcanvas();
        }
      })
      .catch(error => console.error('Error:', error));
  }

  async fetchNoOrMultipleSelected() {
    try {
      const noMultiplePitchesUrlElement = document.querySelector(
        '[data-pitch-review-no-multiple-pitches-url-value]'
      );
      const noMultiplePitchesUrlValue = noMultiplePitchesUrlElement.getAttribute(
        'data-pitch-review-no-multiple-pitches-url-value'
      );
            
      const selectedIds = Array.from(this.getCheckedCheckboxes()).map(checkbox => checkbox.value);
      const url = `${noMultiplePitchesUrlValue}?selected_pitch_ids=${selectedIds.join(',')}`;

      const request = new FetchRequest('GET', url);
      const response = await request.perform();
      return await response.text;
    } catch (error) {
      console.error('Error in fetchNoOrMultipleSelected:', error);
      throw error;
    }
  }

  async fetchPitchDetails() {
    try {
      const request = new FetchRequest('GET', this.detailsUrlValue);
      const response = await request.perform();
      return await response.text;
    } catch (error) {
      console.error('Error fetching pitch details:', error);
      throw error;
    }
  }

  displayContentInRightPanel(htmlContent) {
    document.querySelectorAll('#pitch-offcanvas, #pitch-right-panel').forEach(frame => {
      frame.innerHTML = htmlContent;
    });
  }

  async bulkAccept(event) {
    event.preventDefault();
    const pitchIds = this.getSelectedPitchIds();
    try {
      const url = this.element.dataset.bulkActionUrlApprove;
      const response = await this._submitBulkAccept(url, pitchIds);
      if (response.ok) {
        window.location.href = window.location.pathname;
      }
    } catch (error) {
      console.error('Bulk approve failed:', error);
    }
  }

  async _submitBulkAccept(url, pitchIds) {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const request = new FetchRequest('POST', url, {
      body: JSON.stringify({ resource_ids: pitchIds }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    });
    return await request.perform();
  }

  async bulkDecline(event) {
    event.preventDefault();

    const pitchIds = this.getSelectedPitchIds();

    try {
      const waitForElement = (selector) => {
        return new Promise((resolve) => {
          const element = document.querySelector(selector);
          if (element) {
            resolve(element);
            return;
          }
          const observer = new MutationObserver((mutations, observerInstance) => {
            const element = document.querySelector(selector);
            if (element) {
              observerInstance.disconnect();
              resolve(element);
            }
          });

          observer.observe(document.body, { childList: true, subtree: true });
        });
      };

      await waitForElement('#pitchDetailPanel');
      const url = this.element.dataset.bulkActionFormUrlDecline;
      const token = document.querySelector('meta[name="csrf-token"]').content;

      const request = new FetchRequest('PUT', url, {
        body: JSON.stringify({ resource_ids: pitchIds }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        responseKind: 'text'
      });

      const response = await request.perform();

      if (response.ok) {
        const offcanvas = new Offcanvas(document.getElementById('pitchDetailPanel'));
        offcanvas.show();

        const offcanvasBody = document.querySelector('#pitchDetailPanel .offcanvas-body');
        if (offcanvasBody) {
          offcanvasBody.innerHTML = '<div id="viewing_pitch" class="h-100"></div>';
          setTimeout(() => {
            const buttonContainer = document.getElementById('bulk-decline-buttons');
            if (buttonContainer) {
              buttonContainer.classList.remove('d-none', 'd-lg-flex');
              buttonContainer.classList.add('d-flex');
            }
          }, 0);
        }
      }
    } catch (error) {
      console.error('Bulk decline form fetch failed:', error);
    }
  }

  disconnect() {
    document.querySelectorAll('.offcanvas-backdrop').forEach(backdrop => {
      backdrop.remove();
    });

    this.selectedCheckboxes.clear();
  }
}